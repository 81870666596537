define("discourse/plugins/discourse-post-voting/discourse/components/post-voting-comment-actions", ["exports", "@ember/component", "@glimmer/component", "@ember/object", "@ember/service", "discourse/components/modal/flag", "discourse/lib/ajax", "discourse/lib/ajax-error", "I18n", "discourse/plugins/discourse-post-voting/discourse/lib/post-voting-flag", "@ember/template-factory"], function (_exports, _component, _component2, _object, _service, _flag, _ajax, _ajaxError, _I18n, _postVotingFlag, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.canEdit}}
    <span class="post-voting-comment-actions">
      <DButton
        @display="link"
        class="post-voting-comment-actions-edit-link"
        @action={{@updateComment}}
        @icon="pencil-alt"
      />
      <DButton
        @display="link"
        class="post-voting-comment-actions-delete-link"
        @action={{this.deleteConfirm}}
        @icon="far-trash-alt"
      />
  
      {{#if this.canFlag}}
        <DButton
          @display="link"
          class="post-voting-comment-actions-flag-link"
          @action={{this.showFlag}}
          @icon="flag"
        />
      {{/if}}
    </span>
  {{/if}}
  */
  {
    "id": "fwDRcrvf",
    "block": "[[[41,[30,0,[\"canEdit\"]],[[[1,\"  \"],[10,1],[14,0,\"post-voting-comment-actions\"],[12],[1,\"\\n    \"],[8,[39,1],[[24,0,\"post-voting-comment-actions-edit-link\"]],[[\"@display\",\"@action\",\"@icon\"],[\"link\",[30,1],\"pencil-alt\"]],null],[1,\"\\n    \"],[8,[39,1],[[24,0,\"post-voting-comment-actions-delete-link\"]],[[\"@display\",\"@action\",\"@icon\"],[\"link\",[30,0,[\"deleteConfirm\"]],\"far-trash-alt\"]],null],[1,\"\\n\\n\"],[41,[30,0,[\"canFlag\"]],[[[1,\"      \"],[8,[39,1],[[24,0,\"post-voting-comment-actions-flag-link\"]],[[\"@display\",\"@action\",\"@icon\"],[\"link\",[30,0,[\"showFlag\"]],\"flag\"]],null],[1,\"\\n\"]],[]],null],[1,\"  \"],[13],[1,\"\\n\"]],[]],null]],[\"@updateComment\"],false,[\"if\",\"d-button\"]]",
    "moduleName": "discourse/plugins/discourse-post-voting/discourse/components/post-voting-comment-actions.hbs",
    "isStrictMode": false
  });
  class PostVotingCommentActions extends _component2.default {
    static #_ = (() => dt7948.g(this.prototype, "dialog", [_service.inject]))();
    #dialog = (() => (dt7948.i(this, "dialog"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "modal", [_service.inject]))();
    #modal = (() => (dt7948.i(this, "modal"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "currentUser", [_service.inject]))();
    #currentUser = (() => (dt7948.i(this, "currentUser"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "siteSettings", [_service.inject]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    static #_5 = (() => dt7948.g(this.prototype, "site", [_service.inject]))();
    #site = (() => (dt7948.i(this, "site"), void 0))();
    comment = this.args.comment;
    hasPermission() {
      return this.comment.user_id === this.currentUser.id || this.currentUser.admin || this.currentUser.moderator;
    }
    get canEdit() {
      return this.currentUser && this.hasPermission && !this.args.disabled;
    }
    get canFlag() {
      return this.currentUser && (this.hasPermission || this.currentUser.can_flag_post_voting_comments) && !this.args.disabled;
    }
    deleteConfirm() {
      this.dialog.deleteConfirm({
        message: _I18n.default.t("post_voting.post.post_voting_comment.delete.confirm"),
        didConfirm: () => {
          const data = {
            comment_id: this.args.id
          };
          (0, _ajax.ajax)("/post_voting/comments", {
            type: "DELETE",
            data
          }).then(() => {
            this.args.removeComment(this.args.id);
          }).catch(_ajaxError.popupAjaxError);
        }
      });
    }
    static #_6 = (() => dt7948.n(this.prototype, "deleteConfirm", [_object.action]))();
    showFlag() {
      this.comment.availableFlags = this.comment.available_flags;
      this.modal.show(_flag.default, {
        model: {
          flagTarget: new _postVotingFlag.default(),
          flagModel: this.comment,
          setHidden: () => this.comment.hidden = true,
          site: this.site
        }
      });
    }
    static #_7 = (() => dt7948.n(this.prototype, "showFlag", [_object.action]))();
  }
  _exports.default = PostVotingCommentActions;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, PostVotingCommentActions);
});