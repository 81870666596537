define("discourse/plugins/discourse-follow/discourse/components/follow-users-list", ["exports", "@ember/component", "@ember/object/computed", "discourse/lib/computed", "discourse-common/utils/decorators"], function (_exports, _component, _computed, _computed2, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend(dt7948.p({
    hasUsers: (0, _computed.notEmpty)("users"),
    viewingSelf: (0, _computed2.propertyEqual)("user.username", "currentUser.username"),
    noneMessage(type, viewingSelf) {
      let key = viewingSelf ? "none" : "none_other";
      return `user.${type}.${key}`;
    }
  }, [["method", "noneMessage", [(0, _decorators.default)("type", "viewingSelf")]]]));
});