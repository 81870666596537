define("discourse/plugins/discourse-follow/discourse/initializers/follow-initializer", ["exports", "discourse/lib/plugin-api", "discourse/lib/url", "I18n"], function (_exports, _pluginApi, _url, _I18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: "follow-plugin-initializer",
    initialize(/*container*/
    ) {
      (0, _pluginApi.withPluginApi)("0.8.10", api => {
        const currentUser = api.getCurrentUser();
        if (!currentUser) {
          return;
        }
        api.replaceIcon("notification.following", "discourse-follow-new-follower");
        api.replaceIcon("notification.following_created_topic", "discourse-follow-new-topic");
        api.replaceIcon("notification.following_replied", "discourse-follow-new-reply");
        if (api.registerNotificationTypeRenderer) {
          api.registerNotificationTypeRenderer("following", NotificationTypeBase => {
            return class extends NotificationTypeBase {
              get linkTitle() {
                return _I18n.default.t("notifications.titles.following");
              }
              get linkHref() {
                return (0, _url.userPath)(this.notification.data.display_username);
              }
              get icon() {
                return "discourse-follow-new-follower";
              }
              get label() {
                return this.notification.data.display_username;
              }
              get description() {
                return _I18n.default.t("notifications.following_description", {});
              }
            };
          });
        }

        // workaround to make core save custom fields when changing
        // preferences
        api.modifyClass("controller:preferences/notifications", {
          pluginId: "discourse-follow-notification-preference",
          actions: {
            save() {
              if (!this.saveAttrNames.includes("custom_fields")) {
                this.saveAttrNames.push("custom_fields");
              }
              this._super(...arguments);
            }
          }
        });
      });
    }
  };
});