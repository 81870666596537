define("discourse/plugins/discourse-post-voting/discourse/initializers/extend-composer-actions", ["exports", "discourse/lib/plugin-api", "discourse/models/composer", "discourse-common/utils/decorators", "I18n"], function (_exports, _pluginApi, _composer, _decorators, _I18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: "extend-composer-actions",
    after: "inject-objects",
    initialize(container) {
      const siteSettings = container.lookup("service:site-settings");
      if (!siteSettings.post_voting_enabled) {
        return;
      }
      (0, _pluginApi.withPluginApi)("0.13.0", api => {
        api.serializeOnCreate("create_as_post_voting", "createAsPostVoting");
        api.serializeOnCreate("only_post_voting_in_this_category", "onlyPostVotingInThisCategory");
        api.customizeComposerText({
          actionTitle(model) {
            if (model.createAsPostVoting || model.onlyPostVotingInThisCategory) {
              return _I18n.default.t("composer.create_post_voting.label");
            } else if (model.topic?.is_post_voting) {
              return _I18n.default.t("post_voting.topic.answer.label");
            } else {
              return null;
            }
          },
          saveLabel(model) {
            if (model.createAsPostVoting || model.onlyPostVotingInThisCategory) {
              return "composer.create_post_voting.label";
            } else if (model.topic?.is_post_voting) {
              return "post_voting.topic.answer.label";
            } else {
              return null;
            }
          }
        });
        api.modifyClass("component:composer-actions", {
          pluginId: "discourse-post-voting",
          togglePostVotingSelected(options, model) {
            model.toggleProperty("createAsPostVoting");
            model.notifyPropertyChange("replyOptions");
            model.notifyPropertyChange("action");
          }
        });
        api.modifySelectKit("composer-actions").appendContent(options => {
          if (options.action === _composer.CREATE_TOPIC) {
            if (options.composerModel.createAsPostVoting && !options.composerModel.onlyPostVotingInThisCategory) {
              return [{
                name: _I18n.default.t("composer.composer_actions.remove_as_post_voting.label"),
                description: _I18n.default.t("composer.composer_actions.remove_as_post_voting.desc"),
                icon: "plus",
                id: "togglePostVoting"
              }];
            } else if (options.composerModel.onlyPostVotingInThisCategory) {
              return [];
            } else {
              return [{
                name: _I18n.default.t("composer.composer_actions.create_as_post_voting.label"),
                description: _I18n.default.t("composer.composer_actions.create_as_post_voting.desc"),
                icon: "plus",
                id: "togglePostVoting"
              }];
            }
          } else {
            return [];
          }
        });
        api.modifyClass("model:composer", dt7948.p({
          pluginId: "discourse-post-voting",
          categoryCreateAsPostVotingDefault() {
            const createAsPostVoting = this.category?.create_as_post_voting_default;
            const onlyPostVotingInThisCategory = this.category?.only_post_voting_in_this_category;
            if (this.creatingTopic && onlyPostVotingInThisCategory) {
              this.set("createAsPostVoting", true);
              this.set("onlyPostVotingInThisCategory", onlyPostVotingInThisCategory);
              this.notifyPropertyChange("replyOptions");
              this.notifyPropertyChange("action");
            } else if (this.creatingTopic && createAsPostVoting !== this.createAsPostVoting) {
              this.set("createAsPostVoting", createAsPostVoting);
              this.notifyPropertyChange("replyOptions");
              this.notifyPropertyChange("action");
            }
          }
        }, [["method", "categoryCreateAsPostVotingDefault", [(0, _decorators.observes)("categoryId")]]]));
      });
    }
  };
});