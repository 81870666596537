define("discourse/plugins/discourse-post-voting/discourse/lib/post-voting-utilities", ["exports", "discourse/lib/ajax", "discourse/lib/ajax-error"], function (_exports, _ajax, _ajaxError) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.whoVoted = _exports.removeVote = _exports.castVote = void 0;
  const vote = function (type, data) {
    return (0, _ajax.ajax)("/post_voting/vote", {
      type,
      data
    });
  };
  const removeVote = function (data) {
    return vote("DELETE", data);
  };
  _exports.removeVote = removeVote;
  const castVote = function (data) {
    return vote("POST", data);
  };
  _exports.castVote = castVote;
  const whoVoted = function (data) {
    return (0, _ajax.ajax)("/post_voting/voters", {
      type: "GET",
      data
    }).catch(_ajaxError.popupAjaxError);
  };
  _exports.whoVoted = whoVoted;
});