define("discourse/plugins/discourse-follow/discourse/controllers/feed", ["exports", "@ember/controller", "discourse/lib/computed"], function (_exports, _controller, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _controller.default.extend({
    viewingSelf: (0, _computed.propertyEqual)("model.user.id", "currentUser.id")
  });
});