define("discourse/plugins/discourse-post-voting/discourse/initializers/post-voting-icon", ["exports", "discourse/lib/plugin-api", "discourse/lib/utilities", "I18n", "discourse/plugins/discourse-post-voting/discourse/components/post-voting-comment"], function (_exports, _pluginApi, _utilities, _I18n, _postVotingComment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: "post-voting-icon",
    initialize(container) {
      const siteSettings = container.lookup("service:site-settings");
      if (!siteSettings.post_voting_enabled) {
        return;
      }
      (0, _pluginApi.withPluginApi)("1.18.0", api => {
        api.registerNotificationTypeRenderer("question_answer_user_commented", NotificationTypeBase => {
          return class extends NotificationTypeBase {
            get linkTitle() {
              return _I18n.default.t("notifications.titles.question_answer_user_commented");
            }
            get linkHref() {
              const url = (0, _utilities.postUrl)(this.notification.slug, this.topicId, this.notification.post_number);
              return `${url}#${(0, _postVotingComment.buildAnchorId)(this.notification.data.post_voting_comment_id)}`;
            }
            get icon() {
              return "comment";
            }
          };
        });
      });
    }
  };
});