define("discourse/plugins/discourse-follow/discourse/routes/followers", ["exports", "discourse/lib/ajax", "discourse/routes/discourse"], function (_exports, _ajax, _discourse) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _discourse.default.extend({
    model(/*params*/
    ) {
      return (0, _ajax.ajax)(`/u/${this.paramsFor("user").username}/follow/followers`);
    },
    setupController(controller, model) {
      controller.setProperties({
        users: model,
        user: this.modelFor("user")
      });
    }
  });
});