define("discourse/plugins/discourse-follow/discourse/components/follow-button", ["exports", "@ember/component", "@ember/object", "@ember/object/computed", "discourse/lib/ajax", "discourse/lib/ajax-error", "discourse-common/utils/decorators"], function (_exports, _component, _object, _computed, _ajax, _ajaxError, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend(dt7948.p({
    loading: false,
    isFollowed: (0, _computed.alias)("user.is_followed"),
    canFollow: (0, _computed.alias)("user.can_follow"),
    showButton(user, currentUser) {
      if (!currentUser) {
        return false;
      }
      if (currentUser.id === user.id) {
        return false;
      }
      if (user.suspended) {
        return false;
      }
      if (user.staged) {
        return false;
      }
      if (user.id < 1) {
        // bot
        return false;
      }
      return true;
    },
    labelKey(isFollowed, canFollow) {
      if (isFollowed && canFollow) {
        return "follow.unfollow_button_label";
      } else {
        return "follow.follow_button_label";
      }
    },
    icon(isFollowed, canFollow) {
      if (isFollowed && canFollow) {
        return "user-times";
      } else {
        return "user-plus";
      }
    },
    toggleFollow() {
      const type = this.isFollowed ? "DELETE" : "PUT";
      this.set("loading", true);
      (0, _ajax.ajax)(`/follow/${this.user.username}.json`, {
        type
      }).then(() => {
        this.set("isFollowed", !this.isFollowed);
      }).catch(_ajaxError.popupAjaxError).finally(() => {
        this.set("loading", false);
      });
    }
  }, [["method", "showButton", [(0, _decorators.default)("user", "currentUser")]], ["method", "labelKey", [(0, _decorators.default)("isFollowed", "canFollow")]], ["method", "icon", [(0, _decorators.default)("isFollowed", "canFollow")]], ["method", "toggleFollow", [_object.action]]]));
});