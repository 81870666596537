define("discourse/plugins/discourse-follow/discourse/components/follow-notification-preferences", ["exports", "@ember/component", "discourse-common/utils/decorators"], function (_exports, _component, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const preferences = ["notify_me_when_followed", "notify_followed_user_when_followed", "notify_me_when_followed_replies", "notify_me_when_followed_creates_topic", "allow_people_to_follow_me"];
  var _default = _exports.default = _component.default.extend(dt7948.p({
    _updatePreferences() {
      if (!this.user.custom_fields) {
        this.user.set("custom_fields", {});
      }
      preferences.forEach(p => {
        this.user.set(`custom_fields.${p}`, this.user[p]);
      });
    }
  }, [["method", "_updatePreferences", [(0, _decorators.observes)(...preferences.map(p => `user.${p}`))]]]));
});