define("discourse/plugins/discourse-follow/discourse/components/follow-feed-post", ["exports", "@ember/component", "@glimmer/component", "discourse-common/lib/get-url", "@ember/template-factory"], function (_exports, _component, _component2, _getUrl, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <UserStreamItem @item={{@post}} />
  */
  {
    "id": "9xEHyh6o",
    "block": "[[[8,[39,0],null,[[\"@item\"],[[30,1]]],null]],[\"@post\"],false,[\"user-stream-item\"]]",
    "moduleName": "discourse/plugins/discourse-follow/discourse/components/follow-feed-post.hbs",
    "isStrictMode": false
  });
  class DiscourseReactionsReactionPost extends _component2.default {
    get postUrl() {
      return (0, _getUrl.default)(this.args.post.url);
    }
  }
  _exports.default = DiscourseReactionsReactionPost;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, DiscourseReactionsReactionPost);
});